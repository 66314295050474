<!-- eslint-disable indent -->
<script>
import Swal from 'sweetalert2';
import moment from 'moment';
// import { getUserData } from '@/helpers/user';

export default {
  components: {},
  props: {
    expenses: Array
  },
  methods: {
    showDetails(details) {
        const transferenceDate = details.spentDetail.transferenceDate ? `<p style="">Data: <span style="font-weight: 400;">${moment(details.spentDetail.transferenceDate).format('DD/MM/YYYY HH:mm')}</span></p>` : '';
        const date = details.spentDetail.date ? `<p style="">Data: <span style="font-weight: 400;">${moment(details.spentDetail.date).format('DD/MM/YYYY HH:mm')}</span></p>` : '';
        const transactionDate = details.spentDetail.transactionDate ? `<p style="">Data: <span style="font-weight: 400;">${moment(details.spentDetail.transactionDate).format('DD/MM/YYYY HH:mm')}</span></p>` : '';
        const approved = details.spentDetail.status === 'APPROVED' && 'Aprovado';
        const denied = details.spentDetail.status === 'DENIED' && 'Negado';
        const processing = details.spentDetail.status === 'PROCESSING' && 'Em Processamento';
        const pending = details.spentDetail.status === 'PENDING' && 'Pendente';
        const sheduleCanceled = details.spentDetail.status === 'SCHEDULE_CANCELED' && 'Agendamento Cancelado';
        const scheduled = details.spentDetail.status === 'SCHEDULED' && 'Agendado'

        Swal.fire({
          title: details.spentDetail.description || details.spentDetail.type,
          html: `<div style="text-align: left!important; font-size: 16px; font-weight:600;">
            ${details.spentDetail.accountFavored ? `<p style="">Conta destino: <span style="font-weight: 400;">${details.spentDetail.accountFavored}</span></p>` : ''}
            ${details.valorBRL ? `<p style="">Valor: <span style="font-weight: 400;">R$ ${this.moneyMask(details.valorBRL).charAt(0) === '-' ? this.moneyMask(details.valorBRL).replace('-', '') : this.moneyMask(details.valorBRL)}</span></p>` : ''}
            ${details.nomeEstabelecimento ? `<p style="">Local: <span style="font-weight: 400;"> ${details.nomeEstabelecimento}</span></p>` : ''}
            ${transferenceDate || date || transactionDate}
            <!--${details.spentDetail.description ? `<p style="">Descrição: <span style="font-weight: 400;">${details.spentDetail.description}</span></p>` : ''}-->
            ${details.spentDetail.status && details.descricaoAbreviada !== 'Pagamento de Contas' ? `<p style="">Status: <span style="font-weight: 400;">${approved || denied || processing || pending || sheduleCanceled || scheduled}</span></p>` : ''}
            ${details.spentDetail.transactionId ? `<p style="">Id de Transação: <span style="font-weight: 400;">${details.spentDetail.transactionId}</span></p>` : ''}
            ${details.spentDetail.barCodeNumber ? `<p style="">Código de Barras: <span style="font-weight: 400;">${details.spentDetail.barCodeNumber}</span></p>` : ''}
            ${details.spentDetail.idPaymentConfirmation ? `<p style="">Comprovante: <br/><span style="font-weight: 400;">${details.spentDetail.idPaymentConfirmation}</span></p>` : ''}
            </br>
            <p style="font-weight: 100; ">Para falar diretamente com o nosso atendimento utilize os respectivos e-mail: faleconosco@onlypay.com.br ou WhatsApp: (11) 98171-0193.</p>

          <div>`,
          type: 'info',
          confirmButtonText: 'Ok'
        })
    },
    moneyMask(value) {
      return value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
    },
    isCredit(item) {
      if (item.valorBRL.toString().includes('-')) {
        return { isCredit: false, show: true }
      }
        return { isCredit: true, show: true }
    },
    lineBreak(value = '') {
      // separar por espaços e quebrar a ultima palavra e juntar com o resto
      const words = value.split(' ')
      const lastWord = words.pop()
      return `${words.join(' ')}<br>${lastWord}`
    }
  }
};
</script>

<template>
  <div class="extract">
    <div>
      <table class="table">
        <thead class="table-head">
          <th class="table-title">Valor</th>
          <th class="table-title">Movimentação</th>
          <th class="table-title">Data</th>
          <th class="table-title">Detalhes</th>
        </thead>
        <tbody>
          <tr class="table-body"
            v-for="statement in expenses"
            :key="statement.idEventoAjuste"
          >
            <td
              class="table-row py-0"
              :class="[!isCredit(statement).isCredit ? 'isNotCredit': 'isCredit']"
              style="
                white-space: nowrap;
                vertical-align: middle;
                text-align: start;
              ">
              <!-- {{!isCredit(statement).isCredit ? '' : ''}} -->
              R$ {{moneyMask(statement.valorBRL)}}
            </td>
            <td
              class="table-row"
              style="
                vertical-align: middle;
                text-align: left;
              ">
              <p v-html="lineBreak(statement.nomeEstabelecimento ? statement.nomeEstabelecimento : statement.descricaoAbreviada)" />
            </td>
            <td
              class="table-row"
              style="
                white-space: nowrap;
                vertical-align: middle;
              ">
              {{$moment(statement.dataOrigem).add(3, 'hours').format('DD/MM/YYYY HH:mm')}}
            </td>
            <td class="table-row" v-if="statement.spentDetail"><a @click="showDetails(statement)">Mais</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.extract {
  .table{
    width: 100%;
    text-align: center;
    .table-head {
      .table-title {
        border-bottom: 1px solid #cdcdcd;
        font-weight: 900;
        padding: .75rem .2rem;
      }
    }
    .table-body {
      .table-row {
        padding: .75rem .2rem;
        word-break: break-all!important;
        @media only screen and (max-width: 768px){
          font-size: .8em;
        }
      }

      .isNotCredit {
        color:red;
        padding: 1rem .1rem;
      }

      .isCredit {
        color:green;
        padding: 1rem .1rem;
      }
    }
  }
}
</style>

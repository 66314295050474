var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "extract" }, [
    _c("div", [
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _c(
          "tbody",
          _vm._l(_vm.expenses, function (statement) {
            return _c(
              "tr",
              { key: statement.idEventoAjuste, staticClass: "table-body" },
              [
                _c(
                  "td",
                  {
                    staticClass: "table-row py-0",
                    class: [
                      !_vm.isCredit(statement).isCredit
                        ? "isNotCredit"
                        : "isCredit",
                    ],
                    staticStyle: {
                      "white-space": "nowrap",
                      "vertical-align": "middle",
                      "text-align": "start",
                    },
                  },
                  [
                    _vm._v(
                      "\n            R$ " +
                        _vm._s(_vm.moneyMask(statement.valorBRL)) +
                        "\n          "
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "table-row",
                    staticStyle: {
                      "vertical-align": "middle",
                      "text-align": "left",
                    },
                  },
                  [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.lineBreak(
                            statement.nomeEstabelecimento
                              ? statement.nomeEstabelecimento
                              : statement.descricaoAbreviada
                          )
                        ),
                      },
                    }),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass: "table-row",
                    staticStyle: {
                      "white-space": "nowrap",
                      "vertical-align": "middle",
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm
                            .$moment(statement.dataOrigem)
                            .add(3, "hours")
                            .format("DD/MM/YYYY HH:mm")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                statement.spentDetail
                  ? _c("td", { staticClass: "table-row" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.showDetails(statement)
                            },
                          },
                        },
                        [_vm._v("Mais")]
                      ),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "table-head" }, [
      _c("th", { staticClass: "table-title" }, [_vm._v("Valor")]),
      _c("th", { staticClass: "table-title" }, [_vm._v("Movimentação")]),
      _c("th", { staticClass: "table-title" }, [_vm._v("Data")]),
      _c("th", { staticClass: "table-title" }, [_vm._v("Detalhes")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }